import { print } from 'graphql';
import fs from 'fs';

type Variables = Record<string, unknown>;

export async function writeLastQuery(query: any, variables: Variables, fileName: string): Promise<void> {
    if (process.env.NODE_ENV === 'development') {
        const lastQuery = `${print(query)}\n${JSON.stringify(variables)}`;
        
        if (typeof process !== 'undefined' && process.server) {
            await fs.promises.writeFile(`debug/${fileName}.gql`, lastQuery, { flag: 'w' })
                .catch((err) => console.error(err));
        }
    }
}
