import { defineStore } from 'pinia';

export const useHeaderAttributesStore = defineStore('headerAttributes', () => {
    const headerBackground = ref('white');
    const headerTextTheme = ref('');
    const scrollingUp = ref(false);
    const isHeaderScrolledToTop = ref(true);
    const isCondensed = ref(true);
    const isCondensedOverride = ref(false);
    const openTopicModal = ref(false);
    const openRegionModal = ref(false);

    function updateHeaderStyleAttributes({ background, text = '', condensed = false, condensedOverride = false }) {
        headerBackground.value = background;
        headerTextTheme.value = text;
        if (condensedOverride) {
            isCondensed.value = true;
            isCondensedOverride.value = true;
        } else {
            isCondensed.value = condensed;
        }
    }

    function updateHeaderScrollAttributes(direction = 'down', top = false) {
        scrollingUp.value = direction === 'up';
        isHeaderScrolledToTop.value = top;
    }

    return {
        headerBackground,
        headerTextTheme,
        scrollingUp,
        isHeaderScrolledToTop,
        isCondensed,
        isCondensedOverride,
        openTopicModal,
        openRegionModal,
        updateHeaderStyleAttributes,
        updateHeaderScrollAttributes,
    };
});
