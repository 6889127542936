import { useMediaQuery } from '@vueuse/core';

export const phoneMW = '(min-width: 666px)';
export const tabletMW = '(min-width: 1200px)';
export const desktopMW = '(min-width: 1439px)';

export const phone = '(max-width: 666px)';
export const tablet = '(max-width: 1200px) and (min-width: 667px)';
export const desktop = '(min-width: 1201px)';

export const mobile = '(max-width: 1200px)';

export const isDesktop = useMediaQuery(desktop);
export const isTablet = useMediaQuery(tablet);
export const isPhone = useMediaQuery(phone);

export const isMobile = useMediaQuery(mobile);
