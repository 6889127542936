import { defineStore } from 'pinia';

export const useBodyAttributesStore = defineStore('bodyAttributes', () => {
    const bodyScrollDisabled = ref(false);

    function updateBodyScrollDisabled(isScrollDisabled) {
        bodyScrollDisabled.value = isScrollDisabled;
    }

    return { bodyScrollDisabled, updateBodyScrollDisabled };
});
