<template>
    <header
        ref="header"
        :class="CSSClasses"
        class="site-header"
        @mouseenter="isHovered = true"
        @mouseleave="checkHeaderScrollState()"
        :dir="siteDirection"
    >
        <div class="nav-one">
            <button class="menu-toggle" @click="onMobileMenuToggleClick">
                <Icon v-show="!isActiveMobileMenu" class="nav-icon" name="hamburger" />
                <Icon v-show="isActiveMobileMenu" class="nav-icon" name="cancel" />
            </button>

            <DropdownMenu isFlushTop class="centers-menu nav-link">
                <template #label>
                    <Typography v-if="locale == 'ru'" variant="body" class="current-center-label nav-link nav-link-text"
                        >Берлинский центр Карнеги</Typography
                    >
                    <Typography
                        v-else-if="locale == 'zh'"
                        variant="body"
                        class="current-center-label nav-link nav-link-text"
                        >卡内基中国</Typography
                    >
                    <Typography v-else variant="body" class="current-center-label nav-link nav-link-text">{{
                        $t(`${currentCenter.label}`)
                    }}</Typography>
                </template>

                <template #dropdown>
                    <ul>
                        <template v-for="item in centers">
                            <li v-if="item.slug" :key="item.url">
                                <ClientOnly>
                                    <DropdownMenuItem
                                        :external="true"
                                        :url="`${primaryDomain}${item.path}?lang=${
                                            item.localeCodes.find((code) => code === locale.value) || 'en'
                                        }`"
                                        class="center-menu-item"
                                    >
                                        <CenterPreview :title="item.title" :locations="item.locations" />
                                    </DropdownMenuItem>

                                    <DropdownMenuItem
                                        v-if="item.title === 'Carnegie Endowment for International Peace'"
                                        :external="true"
                                        :url="`${primaryDomain}/programs/carnegie-california?lang=${
                                            item.localeCodes.find((code) => code === locale.value) || 'en'
                                        }`"
                                        class="center-menu-item california"
                                    >
                                        <div class="center-preview-california">
                                            <div class="center-info">
                                                <Icon name="location" class="location-icon"></Icon>
                                                <ul class="center-locations">
                                                    <Typography
                                                        :key="california"
                                                        as="li"
                                                        variant="label-small"
                                                        class="center-location"
                                                    >
                                                        {{ $t('California, USA') }}
                                                    </Typography>
                                                </ul>
                                            </div>
                                        </div>
                                    </DropdownMenuItem>
                                </ClientOnly>
                            </li>
                        </template>
                    </ul>
                </template>
            </DropdownMenu>

            <nav class="main-links">
                <ul>
                    <li v-for="link in navLinks.main" :key="link.url">
                        <Anchor :url="link.url" class="nav-link">
                            <Typography
                                :variant="link.isBlogLink ? 'body-italic' : 'body'"
                                transform="capitalize"
                                class="nav-link-text"
                                >{{ $t(link.label) }}</Typography
                            >
                        </Anchor>
                    </li>
                    <li v-if="locale !== 'hi'">
                        <DropdownMenu :isFlushTop="true" class="more-menu nav-link">
                            <template #label>
                                <Typography
                                    variant="body"
                                    transform="capitalize"
                                    class="more-menu-label nav-link nav-link-text"
                                    >{{ $t('more') }}
                                </Typography>
                            </template>

                            <template #dropdown>
                                <div class="more-menu-dropdown">
                                    <ul class="more-menu-main">
                                        <li
                                            v-for="link in navLinks.moreMenuPrimary"
                                            :key="link.url"
                                            class="more-menu-main-item"
                                        >
                                            <DropdownMenuItem v-if="link.label !== 'donate'" :url="link.url">
                                                <Typography variant="body" transform="capitalize"
                                                    >{{ $t(link.label) }}
                                                </Typography>
                                            </DropdownMenuItem>
                                        </li>
                                    </ul>

                                    <ul class="more-menu-secondary">
                                        <li
                                            v-for="link in navLinks.moreMenuSecondary"
                                            :key="link.url"
                                            class="more-menu-secondary-item"
                                        >
                                            <Anchor :url="link.url">
                                                <Typography variant="body-micro" transform="capitalize">
                                                    {{ $t(link.label) }}
                                                </Typography>
                                            </Anchor>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </DropdownMenu>
                    </li>
                </ul>
            </nav>
        </div>
        <Anchor :url="getCenterPageUrl('') + '?lang=' + locale" class="logo-wrap">
            <div class="logo-lang">
                <CenterLogo :logoComponent="currentCenter.logos[locale]?.logo" class="nav-logo"></CenterLogo>
            </div>

            <Icon name="logo-lettermark" class="logo-letter-mark nav-logo" />
        </Anchor>
        <div class="nav-two">
            <nav class="topic-chips">
                <Chip
                    v-for="item in headlineTopicRegionTags"
                    :key="item.value.path"
                    :url="item.value.path + '?lang=' + locale"
                    variant="condensed"
                    :theme="chipTheme"
                    class="nav-tag"
                >
                    {{ $t(item.value.title) }}
                </Chip>
            </nav>

            <DropdownMenu isFullWidth isFlushTop :isMenuRelative="false" class="topics-regions-menu nav-link">
                <template #label>
                    <Typography variant="body" class="nav-link-text">{{ $t('topics & regions') }}</Typography>
                </template>
                <template #dropdown>
                    <div class="topics-regions-menu-content">
                        <div class="topics">
                            <div class="topics-regions-menu-heading">
                                <Typography variant="h5" class="topic-regions-title">{{ $t('Topics') }} </Typography>
                                <Button
                                    @click="openTopicModal = true"
                                    variant="tertiary"
                                    class="topics-regions-see-all"
                                    >{{ $t('see all topics') }}</Button
                                >
                            </div>

                            <ul class="topics-regions-list">
                                <li
                                    v-for="topic in featuredCenterTopics"
                                    :key="topic.id"
                                    class="topics-regions-list-item"
                                >
                                    <Anchor :url="topic.path + '?lang=' + locale">
                                        <Typography variant="body" transform="capitalize">
                                            {{ topic.title }}</Typography
                                        >
                                    </Anchor>
                                </li>
                            </ul>
                        </div>

                        <div class="regions">
                            <div class="topics-regions-menu-heading">
                                <Typography variant="h5" class="topic-regions-title">{{ $t('Regions') }}</Typography>
                                <Button
                                    @click="openRegionModal = true"
                                    variant="tertiary"
                                    class="topics-regions-see-all"
                                    >{{ $t('see all regions') }}</Button
                                >
                            </div>

                            <ul class="topics-regions-list">
                                <li
                                    v-for="region in featuredCenterRegions"
                                    :key="region.id"
                                    class="topics-regions-list-item"
                                >
                                    <Anchor :url="region.path + '?lang=' + locale">
                                        <Typography variant="body" transform="capitalize"
                                            >{{ region.title }}
                                        </Typography>
                                    </Anchor>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </DropdownMenu>

            <nav
                v-if="isGlobalCenter || currentCenter.slug === 'europe' || currentCenter.slug === 'russia-eurasia'"
                class="topic-chips extra-padding"
            >
                <Chip :url="'https://donate.carnegieendowment.org/support'" variant="condensed" theme="teal">
                    {{ $t('Donate') }}
                </Chip>
            </nav>

            <DropdownMenu
                v-if="currentLocales.length > 1"
                :isFlushTop="true"
                :items="currentLocales"
                icon="language"
                class="content-language-selector nav-link"
            >
                <template #label>
                    <Typography variant="body" class="current-center-label nav-link-text"
                        >{{ currentLocaleName }}
                    </Typography>
                </template>

                <template #dropdownItem="{ item }">
                    <DropdownMenuItem @click="reloadSiteWithLocale(item)" class="center-menu-item">
                        <Typography as="div" variant="body">{{ item.name }}</Typography>
                    </DropdownMenuItem>
                </template>
            </DropdownMenu>

            <div class="actions">
                <Anchor :url="getCenterPageUrl('search') + '?lang=' + locale" class="search-toggle">
                    <Icon class="nav-icon" name="search" />
                </Anchor>
            </div>
        </div>
        <div class="mobile-menu" v-show="isActiveMobileMenu">
            <div class="top">
                <DropdownMenu CSSClasses="nav-centers" isFlushTop isFullWidth class="centers-menu nav-link">
                    <template #label>
                        <CenterPreview :title="currentCenter.title" :locations="currentCenter.locations" />
                    </template>

                    <template #dropdown>
                        <ul>
                            <template v-for="item in centers">
                                <li v-if="currentCenter.slug !== item.slug" :key="item.url">
                                    <DropdownMenuItem :url="item.path" class="center-menu-item">
                                        <CenterPreview :title="item.title" :locations="item.locations" />
                                    </DropdownMenuItem>
                                </li>
                            </template>
                        </ul>
                    </template>
                </DropdownMenu>

                <nav class="mobile-menu-nav">
                    <ul class="primary-links">
                        <li v-for="link in navLinks.main" :key="link.url" class="primary-link">
                            <Anchor :url="link.url">
                                <Typography :variant="link.isBlogLink ? 'h3-italic' : 'h3'" transform="capitalize">{{
                                    $t(link.label)
                                }}</Typography>
                            </Anchor>
                        </li>
                    </ul>
                    <div class="secondary-links">
                        <ul>
                            <li v-for="link in navLinks.moreMenuPrimary" :key="link.url" class="secondary-link">
                                <Anchor :url="link.url">
                                    <Typography variant="body" transform="capitalize">{{ $t(link.label) }} </Typography>
                                </Anchor>
                            </li>
                        </ul>

                        <ul>
                            <li v-for="link in navLinks.moreMenuSecondary" :key="link.url" class="secondary-link">
                                <Anchor :url="link.url">
                                    <Typography variant="body" transform="capitalize">
                                        {{ $t(link.label) }}
                                    </Typography>
                                </Anchor>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div class="bottom">
                <nav class="mobile-chips">
                    <Chip
                        v-for="item in headlineTopicRegionTags"
                        :key="item.value.path"
                        :url="item.value.path"
                        variant="condensed"
                        theme="light"
                    >
                        {{ item.value.title }}
                    </Chip>
                </nav>
                <div class="mobile-topics-regions-ctas">
                    <Button @click="openTopicModal = true" variant="tertiary" class="topics-regions-cta"
                        >See All Topics</Button
                    >
                    <Button @click="openRegionModal = true" variant="tertiary" class="topics-regions-cta"
                        >See All Regions</Button
                    >
                </div>
            </div>
        </div>

        <ClientOnly>
            <Teleport to="#teleported-content">
                <TopicModal
                    :openModal="openTopicModal"
                    :closeModal="closeTopicRegionModal"
                    :allTopics="sortedTopicsByLetter"
                />
                <RegionModal
                    :openModal="openRegionModal"
                    :closeModal="closeTopicRegionModal"
                    :allRegions="sortedRegions"
                />
            </Teleport>
        </ClientOnly>
    </header>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import sortBy from 'lodash/sortBy';
useHead({
    script: [{ innerHTML: '', src: 'https://Pages.carnegieendowment.org/js/forms2/js/forms2.min.js' }],
});

const { centers, currentCenter, getCenterPageUrl, isGlobalCenter } = useCenters();
const { currentLocales, currentLocaleName, locale } = useLocale();

const { siteDirection } = useDirection();
provide('direction', siteDirection);

const route = useRoute();
const bodyAttributesStore = useBodyAttributesStore();
const isPhoneMW = useMediaQuery(phoneMW);
const isTabletMW = useMediaQuery(tabletMW);
const isDesktopMW = useMediaQuery(desktopMW);
const headerAttributesStore = useHeaderAttributesStore();
const {
    headerBackground,
    headerTextTheme,
    scrollingUp,
    isHeaderScrolledToTop,
    isCondensed,
    isCondensedOverride,
    openTopicModal,
    openRegionModal,
} = storeToRefs(headerAttributesStore);
const { currentCenterConfig, currentCenterTopics, currentCenterRegions } = storeToRefs(useCentersConfigStore());
const isHovered = ref(false);

const featuredCenterTopics = computed(() => {
    return currentCenterConfig.value?.featuredTopics;
});

const featuredCenterRegions = computed(() => {
    return currentCenterConfig.value?.featuredRegions;
});

const headlineTopicRegionTags = computed(() => {
    return currentCenterConfig.value?.headlineTopicRegionTags;
});
const isActiveMobileMenu = ref(false);

const onMobileMenuToggleClick = () => {
    setHeaderHeights();
    setMobileMenuActive(!isActiveMobileMenu.value);
};
const setMobileMenuActive = (isActive) => {
    isActiveMobileMenu.value = isActive;
    bodyAttributesStore.updateBodyScrollDisabled(isActive);
    isHovered.value = isActive;
};

const primaryDomain = `${process.client ? window.location.protocol + '//' : 'https://'}${
    import.meta.env.VITE_PRIMARY_DOMAIN
}`;

watch(
    () => route.fullPath,
    () => {
        setMobileMenuActive(false);
    },
);

watch(isTabletMW, (isDesktop) => {
    if (isDesktop && isActiveMobileMenu) {
        setMobileMenuActive(false);
    }
});

onKeyStroke('Escape', () => {
    setMobileMenuActive(false);
});

const CSSClasses = computed(() => [
    `direction-${siteDirection.value}`,
    `background-${headerBackground.value}`,
    headerTextTheme.value && `text-${headerTextTheme.value}`,
    scrollingUp.value && 'fixed visible',
    !isHeaderScrolledToTop.value && 'fixed',
    isHovered.value && 'hovered',
    isCondensed.value && 'condensed',
    currentCenter.value?.slug,
    `lang-${locale.value}`,
]);

const header = ref(null);
let headerHeight = 0;
const windowHeight = useWindowSize().height;

const setHeaderHeights = () => {
    headerHeight = header.value?.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    document.documentElement.style.setProperty('--window-height', `${windowHeight.value}px`);
};

const checkHeaderScrollState = () => {
    if (scrollingUp.value) {
        isHovered.value = true;
    }

    if (isHeaderScrolledToTop.value && !isActiveMobileMenu.value) {
        isHovered.value = false;
    }
};

const chipTheme = computed(() => {
    return isHeaderScrolledToTop.value && !isHovered.value && headerTextTheme.value === 'light' ? 'transparent' : null;
});

onMounted(() => {
    setHeaderHeights();
});
watch([isPhoneMW, isTabletMW, isDesktopMW], () => {
    setHeaderHeights();
});

useSeoMeta({
    title: `${currentCenter.value.title}`,
});

if (process.client) {
    const { y, isScrolling, arrivedState, directions: scrollDirections } = useScroll(document);
    const { top } = toRefs(arrivedState);
    const { top: scrollingUp, bottom: scrollingDown } = toRefs(scrollDirections);

    watch(isScrolling, () => {
        if (!isActiveMobileMenu.value) {
            setHeaderHeights();
            if (isScrolling && scrollingDown.value) {
                headerAttributesStore.updateHeaderScrollAttributes('down');
            }

            if (scrollingDown.value) {
                headerAttributesStore.updateHeaderScrollAttributes('down');
            }

            if (scrollingUp.value && y.value > headerHeight) {
                isCondensed.value = true;
                headerAttributesStore.updateHeaderScrollAttributes('up');
            }

            if (scrollingUp.value) {
                isHovered.value = true;
            }

            if (top.value) {
                headerAttributesStore.updateHeaderScrollAttributes('down', true);
                isHovered.value = false;
                if (!isCondensedOverride.value) {
                    isCondensed.value = false;
                }
            }
        }
    });
}

const closeTopicRegionModal = () => {
    openTopicModal.value = false;
    openRegionModal.value = false;
};

const sortedTopicsByLetter = computed(() => {
    const topicsByLetter = {};
    currentCenterTopics.value?.docs?.forEach((topic) => {
        const letter = topic.title.charAt(0).toUpperCase();
        if (Object.hasOwn(topicsByLetter, letter)) {
            const topicsArray = topicsByLetter[letter];
            topicsArray.push(topic);
            topicsByLetter[letter] = sortBy(topicsArray, 'title');
        } else {
            topicsByLetter[letter] = [topic];
        }
    });

    return sortBy(Object.entries(topicsByLetter));
});

const sortedRegions = computed(() => {
    const countriesByRegion = {};
    currentCenterRegions.value?.docs?.forEach((region) => {
        if (region.regions) {
            region.regions.forEach((item) => {
                const regionKey = item.title;
                if (
                    Object.hasOwn(countriesByRegion, regionKey) &&
                    currentCenterRegions.value?.docs.find((region) => region.title === regionKey)
                ) {
                    const countriesArray = countriesByRegion[regionKey].countries;
                    countriesArray.push({ path: region.path, title: region.title });
                    countriesByRegion[regionKey].countries = sortBy(countriesArray, 'title');
                } else if (currentCenterRegions.value?.docs.find((region) => region.title === regionKey)) {
                    countriesByRegion[regionKey] = {
                        path: item.path,
                        title: item.title,
                        countries: [{ path: region.path, title: region.title }],
                    };
                }
            });
        } else {
            countriesByRegion[region.title] = { path: region.path, title: region.title };
        }
    });

    return sortBy(Object.values(countriesByRegion), 'title');
});

const navLinks = computed(() => ({
    main: [
        { url: getCenterPageUrl('research') + '?lang=' + locale._value, label: 'research' },
        ...(currentCenter.value.title === 'Carnegie China'
            ? [{ url: '/china/carnegie-china-commentaries?lang=en', label: 'commentaries' }]
            : []),
        ...(currentCenter.value.blog && locale._value !== 'ru'
            ? [
                  {
                      url: currentCenter.value.blog.path + '?lang=' + locale._value,
                      label: currentCenter.value.blog.title,
                      isBlogLink: true,
                  },
              ]
            : []),
        ...(currentCenter.value.blog && locale._value === 'ru'
            ? [{ url: currentCenter.value.blog.path + '?lang=' + locale._value, label: 'Politika', isBlogLink: true }]
            : []),
        ...(locale._value !== 'ru'
            ? [{ url: currentCenter.value.about + '?lang=' + locale._value, label: 'about us' }]
            : []),
        { url: getCenterPageUrl('experts') + '?lang=' + locale._value, label: 'experts' },
        ...(currentCenter.value.title === 'Carnegie China'
            ? [{ url: getCenterPageUrl('events') + '?lang=' + locale._value, label: 'events' }]
            : []),
    ],
    moreMenuPrimary: [
        ...(locale._value === 'ru'
            ? [{ url: currentCenter.value.about + '?lang=' + locale._value, label: 'about us' }]
            : []),
        ...(isGlobalCenter.value ? [{ url: 'https://donate.carnegieendowment.org/support', label: 'donate' }] : []),
        ...(isGlobalCenter.value ? [{ url: '/development', label: 'support' }] : []),
        ...(currentCenter.value.title !== 'Carnegie China' &&
        currentCenter.value.title !== 'Carnegie Russia Eurasia Center'
            ? [
                  {
                      url: getCenterPageUrl('programs-and-projects') + '?lang=' + locale._value,
                      label: `${isGlobalCenter.value ? 'programs' : 'projects'}`,
                  },
              ]
            : []),
        ...(currentCenter.value.title !== 'Carnegie China'
            ? [{ url: getCenterPageUrl('events') + '?lang=' + locale._value, label: 'events' }]
            : []),
        ...(isGlobalCenter.value ? [{ url: getCenterPageUrl('blogs'), label: 'blogs' }] : []),
        ...(isGlobalCenter.value ? [{ url: getCenterPageUrl('podcasts'), label: 'podcasts' }] : []),
        ...(isGlobalCenter.value ? [{ url: '/carnegie-explainer-videos?lang=en', label: 'videos' }] : []),
        ...(currentCenter.value.title === 'Carnegie China' && locale._value === 'en'
            ? [{ url: '/china-financial-markets?lang=en', label: 'China Financial Markets' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie China' && locale._value === 'en'
            ? [{ url: '/china/china-through-a-sea-lens?lang=en', label: 'China Through a SEA Lens' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie China' && locale._value === 'en'
            ? [{ url: '/china/the-young-ambassadors-program?lang=en', label: 'Young Ambassadors Program' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie China' && locale._value === 'en'
            ? [{ url: '/podcasts/china-in-the-world?lang=en', label: 'China in the World Podcast' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie China' && locale._value === 'zh'
            ? [{ url: '/china/the-young-ambassadors-program?lang=zh', label: '青年大使项目' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie Europe'
            ? [{ url: '/podcasts/europe-inside-out?lang=en', label: 'Europe Inside Out Podcast' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie India'
            ? [{ url: '/podcasts/interpreting-india?lang=en', label: 'Interpreting India Podcast' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie India'
            ? [{ url: '/india/newsletters-from-carnegie-india?lang=en', label: 'Newsletters' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie India'
            ? [{ url: '/india/carnegie-india-video-series?lang=en', label: 'Video Series' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie Russia Eurasia Center' && locale._value === 'ru'
            ? [{ url: '/podcasts/baunovcast?lang=ru', label: ' Бауновкаст' }]
            : []),
        ...(currentCenter.value.title === 'Carnegie Russia Eurasia Center' && locale._value === 'en'
            ? [
                  {
                      url: '/podcasts/carnegie-politika-podcast?lang=en',
                      label: 'Carnegie Politika Podcast',
                  },
              ]
            : []),
    ],
    moreMenuSecondary: [
        // { url: getCenterPageUrl('regional-centers'), label: 'regional centers' },
        ...(isGlobalCenter.value
            ? [
                  {
                      url: '/newsletters-from-the-carnegie-endowment-for-international-peace?lang=en',
                      label: 'Newsletters',
                  },
              ]
            : []),
        ...(isGlobalCenter.value ? [{ url: getCenterPageUrl('annual-reports'), label: 'Annual Reports' }] : []),
        ...(currentCenter.value.title !== 'Carnegie China' &&
        currentCenter.value.title !== 'Carnegie Russia Eurasia Center'
            ? [{ url: currentCenter.value.careers + '?lang=' + locale._value, label: 'careers' }]
            : []),
    ],
}));

const onLanguageClick = (locale) => {
    reloadAppWithLocale(locale.code);
};
const reloadSiteWithLocale = (locale) => {
    if (process.client) {
        reloadNuxtApp({
            path: getCenterPageUrl('') + '?lang=' + locale.code,
        });
    }
};
</script>

<style lang="scss" scoped>
$transition-speed: 0.2s;
.logo-lang {
    display: flex;
    align-items: center;

    img {
        height: 100%;
    }
}

.nav-logo {
    color: color(blue);
}
.logo-letter-mark {
    height: var(--col-gutter);
    display: none;
}

.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    padding: 2.2rem 0;
    background: color(blanc, default); //removing opacity 1 to keep white all the time
    transition-timing-function: ease;
    transition-duration: $transition-speed;
    transition-property: top, height, background;
    transition-delay: 0s, 0s, $transition-speed;
    @include z-index(header);

    @include media-query(phone-mw) {
        padding: 0;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &.hovered,
    &:hover {
        transition-delay: 0s;
        background: color(blanc);
        .nav-link,
        .nav-icon,
        .nav-logo {
            transition-delay: 0s;
        }
        @include media-query(phone) {
            @include z-index(modal);
        }
    }

    &.fixed {
        background: color(blanc);
        // top: calc(var(--header-height) * -1);

        // &.visible {
        //     top: 0;
        // }
    }

    &:not(.fixed) {
        &:not(.hovered) {
            &.background-white {
                background: color(blanc);
            }

            &.background-grey {
                background: color(blanc);
                // background: color(grey, light);
            }

            &.text-light {
                // transition-timing-function: ease;
                // transition-property: top, background;
                // transition-duration: $transition-speed, 0s;
                // transition-delay: 0s;
                // background: color(blanc);
                // background: linear-gradient(to bottom, rgba(var(--color-rgb-noir-default), 0.3), transparent);
                // .nav-link,
                // .nav-icon,
                // .nav-logo {
                //     color: color(blanc, default);
                //     transition-delay: 0s;
                //     transition-duration: 0s;
                // }
                // .nav-one {
                //     .centers-menu {
                //         &::after {
                //             background-color: color(blanc, default, 0.4);
                //         }
                //     }
                // }

                // .nav-two {
                //     .search-toggle {
                //         &::before {
                //             background-color: color(blanc, default, 0.4);
                //         }
                //     }
                // }
            }
        }
    }

    &.condensed {
        @include media-query(phone) {
            padding: 1.2rem 0;
        }

        .logo-wrap {
            @include media-query(phone-mw) {
                padding: 1.2rem 0;
            }
        }

        .logo-lang {
            display: none;
        }

        .logo-letter-mark {
            display: block;

            @include media-query(phone-mw) {
                height: 3.4rem;
            }
        }
    }
}
.nav-link,
.nav-icon,
.nav-logo {
    transition-timing-function: ease;
    transition-duration: $transition-speed;
    transition-property: color;
    transition-delay: $transition-speed;
}
.nav-link {
    color: color(blue, dark, 0.66);
    padding: 1rem 0;
    white-space: nowrap;
    @include transition(color, controls);
    &:hover {
        color: color(blue, dark, 0.5);
    }
    &.router-link-active,
    &:active {
        color: color(blue, dark);
    }
}
.nav-link-text {
    @include media-query(tablet-mw) {
        font-size: 1.6rem;
    }
}

.nav-icon {
    color: color(noir, default, 0.4);
}

.logo-wrap {
    display: block;
    justify-self: center;
    grid-column-start: 2;
    transition: padding ease $transition-speed;

    @include media-query(phone-mw) {
        padding: 2.2rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.nav-one {
    padding-inline-start: var(--col-gutter);
    display: flex;
    position: absolute;
    height: 100%;
    top: 0;
    inset-inline-start: 0;

    @include media-query(phone-mw) {
        position: static;
        gap: 1.6rem;
        height: 100%;
    }

    @include media-query(tablet-mw) {
        padding-inline-start: 0;
    }

    .menu-toggle {
        @include media-query(tablet-mw) {
            display: none;
        }
    }

    .centers-menu {
        display: none;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 44%;
            background-color: palette(border);
        }

        @include media-query(tablet-mw) {
            display: flex;
        }
    }
}

.nav-two {
    position: absolute;
    height: 100%;
    top: 0;
    inset-inline-end: 0;

    grid-column: 3;
    justify-self: end;
    display: flex;
    gap: 1.2rem;
    align-items: center;
    height: 100%;
    padding-inline-end: var(--col-gutter);

    @include media-query(phone-mw) {
        position: static;
        padding-inline-end: 0;
    }

    .actions {
        display: flex;
        gap: 1.8rem;
        height: 100%;
    }

    .content-language-selector {
        @include media-query(mobile) {
            .current-center-label.nav-link-text {
                display: none;
            }
        }
    }

    .search-toggle {
        display: flex;
        align-items: center;
        height: 100%;

        @include media-query(phone-mw) {
            padding-inline-start: 1.6rem;
            padding-inline-end: var(--col-gutter);
            padding-top: 0.9rem;
            padding-bottom: 0.9rem;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                inset-inline-start: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 44%;
                background-color: palette(border);
            }
        }
    }
}

.topics-regions-menu {
    display: none;

    @include media-query(phone-mw) {
        display: flex;
        height: 100%;
    }
}

.topics-regions-menu-content {
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
    position: relative;
    @include content-section;
    @include grid;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0.05rem;
        background-color: color(noir);
        opacity: 0.08;
    }

    @include media-query(tablet-sm-and-below) {
        display: flex;
        flex-direction: column;
        gap: vertical-space(2);

        &::after {
            display: none;
        }
    }
}

.topics {
    grid-column: 1 / 6;
}

.regions {
    grid-column: 8 / 13;
}

.topics-regions-menu-heading {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 5.6rem;
    margin-bottom: 2.2rem;

    @include media-query(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.topics-regions-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 5.6rem;
    row-gap: 1.6rem;

    @include media-query(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .topics-regions-list-item {
        color: rgba(var(--color-rgb-blue-dark), 0.9);
        &:hover {
            color: rgba(var(--color-rgb-blue-dark), 0.66);
        }
        &:active {
            color: rgba(var(--color-rgb-blue-dark));
        }
    }
}

.topic-regions-title {
    color: color(noir);
    grid-column: 1 / span 1;
}

.topics-regions-see-all {
    grid-column: 3 / span 1;

    @include media-query(tablet) {
        grid-column: 2 / span 1;
    }
}

.topic-chips {
    display: none;

    @include media-query(tablet-mw) {
        display: flex;
        gap: 0.4rem;
        &.extra-padding {
            margin: 0 0 0 0.8rem;
        }
    }

    @include media-query(full-sm) {
        a ~ a {
            display: none;
        }
    }
}

.middle-east .topic-chips,
.russia-eurasia .topic-chips {
    @include media-query(full-sm) {
        display: none;
    }
}

.main-links {
    display: none;
    align-items: center;

    @include media-query(phone-mw) {
        display: flex;
    }

    & > ul {
        display: flex;
        gap: 1.2rem;
        height: 100%;
        display: flex;
        align-items: center;

        li:nth-child(n + 4) {
            display: none;

            @include media-query(tablet-mw) {
                display: flex;
            }
        }

        @media (max-width: 780px) {
            li:nth-child(n + 3) {
                display: none;
            }
        }

        li,
        .more-menu {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .more-menu-dropdown {
            min-width: 21.7rem;
        }
    }
}

.centers-menu {
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    padding-inline-start: var(--col-gutter);
    padding-inline-end: 1.6rem;
    position: relative;
}

.center-menu-item {
    opacity: 0.66;
    @include transition(opacity, controls);

    &:hover {
        opacity: 0.5;
    }
    &:active,
    &.is-current {
        opacity: 1;
    }
}

.more-menu-label,
.more-menu-main-item,
.more-menu-secondary-item {
    color: rgba(var(--color-rgb-blue-dark), 0.66);

    &:hover {
        color: color(blue, dark, 0.5);
    }
    &:active {
        color: color(blue, dark);
    }
}

.more-menu-main-item {
    border-bottom: 1px solid palette(divider);
}

.more-menu-secondary {
    padding: 2.2rem var(--col-gutter);
}

.more-menu-secondary-item {
    &:not(:last-child) {
        padding-bottom: 1.2rem;
    }
}

.mobile-menu {
    grid-column: 1/-1;
    position: fixed;
    top: var(--header-height);
    width: 100%;
    height: calc(var(--window-height) - var(--header-height));
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: $border;

    @include media-query(tablet-mw) {
        display: none;
    }

    .mobile-menu-nav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: var(--col-gutter);

        @include media-query(phone-mw) {
            grid-template-columns: 1.5fr 1fr;
            padding: 4.6rem 6.2rem;
        }
    }

    .primary-links {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        @include media-query(phone-mw) {
            gap: 2.2rem;
        }
    }

    .secondary-links {
        ul {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            @include media-query(phone-mw) {
                gap: 2.2rem;
            }
        }

        ul:first-child {
            margin-bottom: 3.2rem;

            @include media-query(phone-mw) {
                margin-bottom: 5.2rem;
            }
        }
    }

    .primary-link span {
        color: color(blue, dark);

        @include media-query(phone-mw) {
            font-size: 4.8rem;
        }
    }

    .secondary-link span {
        color: color(blue, dark);

        @include media-query(phone-mw) {
            font-size: 1.8rem;
        }
    }

    .mobile-chips {
        display: flex;
        gap: 0.4rem;
        margin-bottom: 2.5rem;
        padding: 0 var(--col-gutter);

        @include media-query(phone-mw) {
            margin-bottom: 3.2rem;
            padding: 0 6.2rem;
        }
    }

    .mobile-topics-regions-ctas {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: $border;

        .topics-regions-cta {
            width: 100%;
            text-align: center;
            display: block;
            padding-top: 2.9rem;
            padding-bottom: 2.9rem;

            &:first-child {
                border-inline-end: $border;
            }
        }
    }

    .centers-menu {
        border-bottom: $border;
    }
}
.russia-eurasia.lang-ru .more-menu .dropdown-menu-item span.capitalize {
    text-transform: initial;
}
:deep(.center-menu-item.california a.link) {
    padding-top: 0;
}
.center-preview-california {
    .center-info {
        display: flex;
        align-items: flex-start;
        gap: 0.6rem;
        flex-wrap: wrap;
    }
    .location-icon {
        width: 0.8rem;
    }
    .center-locations {
        max-width: 90%;
    }
    .center-location {
        text-align: left;
        white-space: wrap;
        &:not(:last-child) {
            margin-bottom: 0.2rem;
        }
    }
    .center-location,
    svg {
        color: rgb(var(--color-rgb-blue-dark));
    }
}
</style>
