<template>
    <div v-on-click-outside="onClickOutside" @mouseleave="closeMenu()" :class="CSSClasses" class="dropdown-menu">
        <div class="wrapper">
            <button @click="onButtonClick" class="button">
                <Icon v-if="icon" :name="icon" class="icon" />
                <span class="label">
                    <slot v-if="$slots.label" name="label" />
                    <Typography v-else :variant="labelVariant">{{ label || selectedValue }} </Typography>
                </span>
                <div v-if="isArrow" class="arrow-container">
                    <Icon class="arrow" name="arrow-down" />
                </div>
            </button>

            <Transition v-if="$slots.dropdown" :name="!isInFlowOnPhone ? 'vue-dropdown' : ''">
                <div v-show="isActive" class="items">
                    <slot name="dropdown"></slot>
                </div>
            </Transition>

            <Transition v-else :name="!isInFlowOnPhone && 'vue-dropdown'">
                <ul v-show="isActive && items.length" class="items">
                    <li
                        v-for="item in items"
                        :key="item.url || item"
                        @click="onItemClick(item.label || item)"
                        class="item"
                    >
                        <slot name="dropdownItem" v-bind="{ item }">
                            <DropdownMenuItem :url="item.url || item.reference?.path" :newTab="isLinkExternal(item)">
                                {{ item.label }}
                            </DropdownMenuItem>
                        </slot>
                    </li>
                </ul>
            </Transition>
        </div>
    </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components';

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    icon: {
        type: String,
        default: '',
    },
    dropdownAlignment: {
        type: String,
        default: 'right',
    },
    isMenuHidden: {
        type: Boolean,
        default: false,
    },
    isFullWidth: {
        type: Boolean,
        default: false,
    },
    isFlushTop: {
        type: Boolean,
        default: false,
    },
    isFlushRight: {
        type: Boolean,
        default: false,
    },
    isInFlowOnPhone: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },
    labelVariant: {
        type: String,
        default: 'body',
    },
    isLabelLineClamped: {
        type: Boolean,
        default: false,
    },
    isArrow: {
        type: Boolean,
        default: true,
    },
    isMenuRelative: {
        type: Boolean,
        default: true,
    },
});

const isActive = ref(false);
const selectedValue = ref('');
const route = useRoute();

function onButtonClick() {
    isActive.value = !isActive.value;
}

const closeMenu = () => {
    isActive.value = false;
};

function onClickOutside() {
    closeMenu();
}
function onItemClick(itemValue) {
    isActive.value = false;
    selectedValue.value = itemValue;
}

const CSSClasses = computed(() => {
    return [
        props.isFullWidth && 'is-full-width',
        props.isFlushTop && 'is-flush-top',
        props.isFlushRight && 'is-flush-right',
        props.isInFlowOnPhone && 'is-in-flow-phone',
        props.isLabelLineClamped && 'is-label-line-clamped',
        props.isMenuRelative && 'is-menu-relative',
        isActive.value && 'is-active',
    ];
});

watch(
    () => route.fullPath,
    () => {
        isActive.value = false;
    },
);
</script>

<style lang="scss" scoped>
.dropdown-menu {
    @include z-index(above-arbitrary);

    &.is-menu-relative {
        position: relative;
    }
}

.button {
    align-items: center;
    justify-content: space-between;
    display: flex;
    gap: 0.3rem;
    height: 100%;
    width: 100%;
    color: inherit;

    .is-in-flow-phone & {
        @include media-query(phone) {
            padding-block: 1.2em;
            padding-inline-end: 1.6rem;
        }
    }
}

.icon {
    color: inherit;
    opacity: 0.5;
    flex: 1 0 auto;
}

.label {
    text-align: left;

    .is-label-line-clamped & {
        @include text-truncated(2);
    }
}

.arrow-container {
    padding-top: 0.2rem;
    margin-inline-start: 0.4rem;
    opacity: 0.6;
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
}

.arrow {
    .is-active & {
        transform: rotate(180deg);
    }
}

.items {
    position: absolute;
    top: 100%;
    inset-inline-start: 0;
    background: color(blanc);
    width: max-content;
    max-width: 33rem;
    box-shadow: 0 0.4rem 1.5rem 0 #0000001a;
    border: 0.05rem solid palette(divider);

    .is-flush-right & {
        inset-inline-start: auto;
        inset-inline-end: calc(-1 * (2 * var(--page-margin)));
    }

    .is-full-width & {
        width: 100%;
        max-width: none;
    }

    .is-flush-top & {
        box-shadow: none;
    }

    .is-in-flow-phone & {
        @include media-query(phone) {
            position: relative;
            box-shadow: none;
            border: none;
            width: auto;
            max-width: unset;
        }
    }
}

.item {
    &:not(:last-child) {
        border-bottom: 1px solid palette(divider);
    }
}

@include media-query(mobile) {
    .centers-menu .button {
        align-items: baseline;
        .arrow-container {
            justify-content: start;
            margin-left: 1em;
        }
    }
}
</style>
