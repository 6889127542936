<template>
    <NuxtLink :to="linkUrl" :class="variants" class="anchor" :target="target" external>
        <slot />
    </NuxtLink>
</template>

<script setup>
import { isLinkExternal } from '~/utils/urls';
const { centers, currentCenter } = useCenters();

const props = defineProps({
    url: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: '',
    },
    variants: {
        type: Array,
        default: () => [],
    },
});

const linkUrl = computed(() => {
    const getQueryParams = () => {
        for (let i = 0; i < centers.length; i++) {
            const urlHasCenter = props.url?.startsWith(`/${centers[i].slug}`);
            const hasLangParam = props.url?.includes('lang=');
            const outsideLink = props.url?.startsWith('http');
            const urlHasJumpLink = props.url?.includes('#');
            if (urlHasCenter || props.url === '/' || currentCenter.value.slug === 'global' || outsideLink || urlHasJumpLink) {
                return '';
            } else if (hasLangParam) {
                return `&center=${currentCenter.value.slug}`;
            }
        }
        return `?center=${currentCenter.value.slug}`;
    };

    const queryParams = getQueryParams();

    return `${props.url}${queryParams}`;
});

const target = computed(() => (isLinkExternal(props) ? '_blank' : ''));
</script>

<style lang="scss" scoped></style>
