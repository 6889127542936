import revive_payload_client_MPpxHopvMc from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5iJYRIPW8Q from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_r41T6gr1rb from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4qXri2OhhP from "/home/node/app/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.2_sass@1.77._ox43lond6ah3r7jj7jot25o6kq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_SanmpvPnIJ from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_K0GKH5k2ER from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_620yYdFcem from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_yI5in81xzk from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_gJzXF6tepK from "/home/node/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.21.2_typescript@4.9.5_vue@3.5.0_typescript@4.9.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_IPvX8BOhrT from "/home/node/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jq7chgbtrfqzbn3jqh4wcsidti/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_nMs95vioSd from "/home/node/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.20_h3@1.13.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.2__3zw7utl6o5a5whibl76ghtn4uu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_jfTpfg7WJP from "/home/node/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.20_h3@1.13.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.2__3zw7utl6o5a5whibl76ghtn4uu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_2PBY7LrnD2 from "/home/node/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.2_sass@1._vhznojtcpqgcannkcvj7fjyrbm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_FK059ADtdu from "/home/node/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.2_sass@1._vhznojtcpqgcannkcvj7fjyrbm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_uLSGSfRVAW from "/home/node/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_QjT4Yz15by from "/home/node/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_2D0S7pjK9D from "/home/node/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_@types+react@18.3.5_magicast@0.3.5_react-dom@18.3.1_react@18.3._og44mwo3cy6d53bs3kempd73hu/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_OyYaXY09qk from "/home/node/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.21.2_types_odkchb5rogpk6yjtcdc7squb4u/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import uid_rOpAaGNwsx from "/home/node/app/frontend/plugins/uid.ts";
import i18n_VfGcjrvSkj from "/home/node/app/frontend/plugins/i18n.ts";
import errors_5qvZOSbheL from "/home/node/app/frontend/plugins/errors.ts";
import sentry_3AyO8nEfhE from "/home/node/app/frontend/plugins/sentry.ts";
export default [
  revive_payload_client_MPpxHopvMc,
  unhead_5iJYRIPW8Q,
  router_r41T6gr1rb,
  _0_siteConfig_4qXri2OhhP,
  payload_client_SanmpvPnIJ,
  navigation_repaint_client_K0GKH5k2ER,
  check_outdated_build_client_620yYdFcem,
  chunk_reload_client_yI5in81xzk,
  plugin_vue3_gJzXF6tepK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IPvX8BOhrT,
  titles_nMs95vioSd,
  defaultsWaitI18n_jfTpfg7WJP,
  siteConfig_2PBY7LrnD2,
  inferSeoMetaPlugin_FK059ADtdu,
  switch_locale_path_ssr_uLSGSfRVAW,
  i18n_QjT4Yz15by,
  plugin_2D0S7pjK9D,
  plugin_OyYaXY09qk,
  uid_rOpAaGNwsx,
  i18n_VfGcjrvSkj,
  errors_5qvZOSbheL,
  sentry_3AyO8nEfhE
]