<template>
    <SiteModal :showHeader="false" :showFooter="false">
        <template #content>
            <div class="embargo-content-wrapper">
                <div class="content">
                    <Typography class="title" variant="h3-display">Enter password to see content:</Typography>
                    <div class="input-wrapper">
                        <input
                            class="password-field"
                            type="password"
                            required
                            placeholder="Enter password"
                            v-model="passwordString"
                        />
                        <Button @click="onSubmitHandler">submit</Button>
                    </div>
                    <Typography v-if="showError" class="message" as="div">
                        <em>Please enter correct password</em>
                    </Typography>
                </div>
            </div>
        </template>
    </SiteModal>
</template>

<script setup>
const emit = defineEmits('close-embargo-modal');

const passwordString = ref('');
const showError = ref(false);

const onSubmitHandler = () => {
    if (passwordString.value === 'carnegie') {
        showError.value = false;
        emit('close-embargo-modal');
    } else {
        passwordString.value = '';
        showError.value = true;
    }
};
</script>

<style lang="scss" scoped>
.embargo-content-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .content {
        padding: vertical-space(3);
        max-width: 64rem;
        margin: 0 auto;
    }

    .title {
        color: color(noir, default, 0.6);
    }

    .input-wrapper {
        background: color(grey, light, 0.3);
        border: $border;
        padding: 1rem;
        border-radius: 0.3rem;
        margin: vertical-space(2) 0;
        display: flex;
        gap: vertical-space(1);
        flex-wrap: wrap;
    }

    .password-field {
        padding: 0.5rem vertical-space(1);
        flex-grow: 1;
        @include type-h5-display;
        &::placeholder {
            font-style: italic;
        }
    }

    .message {
        margin: vertical-space(2) 0;
        color: color(yellow);
    }
}
</style>
