<template>
    <Anchor :url="destination" class="icon-link" :class="CSSClasses" :type="`${props.type}`">
        <div :class="`icon size-${props.size}`">
            <Icon :name="icon" :size="size" />
        </div>
        <Typography class="icon-label" v-if="label" variant="body">{{ label }}</Typography>
    </Anchor>
</template>
<script setup>
const props = defineProps({
    destination: {
        type: String,
        required: true,
    },
    icon: {
        type: String,
        default: 'link',
    },
    theme: {
        type: String,
        default: 'light',
        validator: (value) => {
            return ['dark', 'light'].includes(value);
        },
    },
    size: {
        type: String,
        default: 'large',
        validator: (value) => {
            return ['small', 'medium', 'large'].includes(value);
        },
    },
    overrideColor: {
        type: String,
        default: '',
        required: false,
    },
    label: {
        type: String,
        default: '',
        required: false,
    },
    type: {
        type: String,
        default: '',
        required: false,
    },
});

const CSSClasses = computed(() => {
    return [`theme-${props.theme}`, props.overrideColor && 'has-overrideColor'];
});
</script>

<style lang="scss" scoped>
.icon-link {
    display: flex;
    align-items: center;
    gap: 1rem;
    .icon-label,
    .size-small {
        @include transition(color, controls);
    }
    .size-medium,
    .size-large {
        @include transition(color border-color background-color, controls);
    }

    // themes
    &.theme {
        &-light {
            .icon-label,
            .size-small {
                color: color(blue, default, 0.5);
            }
            .size-medium,
            .size-large {
                color: color(blue, default, 0.25);
                border-color: color(blue, default, 0.25);
            }

            &:hover {
                .icon-label,
                .size-small {
                    color: color(teal, hover);
                }

                .size-medium,
                .size-large {
                    color: color(teal, hover);
                    border-color: color(teal, hover);
                }
            }
            &:active {
                .icon-label,
                .size-small {
                    color: color(teal, active);
                }

                .size-medium,
                .size-large {
                    color: color(blue);
                    border-color: color(teal, active);
                }
            }
        }

        &-dark {
            .icon-label,
            .size-small {
                color: color(blanc, default, 0.5);
            }
            .size-medium,
            .size-large {
                color: color(blanc, default, 0.5);
                background-color: color(blanc, default, 0);
            }

            &:hover {
                .icon-label,
                .size-small {
                    color: color(blanc);
                }
                .size-medium,
                .size-large {
                    color: color(blanc);
                    background-color: color(teal, hover);
                }
            }
            &:active {
                .icon-label,
                .size-small {
                    color: color(blanc);
                }
                .size-medium,
                .size-large {
                    color: color(blanc);
                    background-color: color(teal, active);
                }
            }
        }
    }
    &.has-overrideColor.theme {
        &-light {
            &:hover,
            &:active {
                .icon-label,
                .size-small {
                    color: v-bind('props.overrideColor');
                }

                .size-medium,
                .size-large {
                    border-color: v-bind('props.overrideColor');
                }
            }
        }

        &-dark {
            &:hover,
            &:active {
                .size-medium,
                .size-large {
                    background-color: v-bind('props.overrideColor');
                }
            }
        }
    }
}
.icon {
    // sizes
    &.size {
        &-small {
            @include square(1.8rem);
        }

        &-medium,
        &-large {
            border-radius: 50%;
            border: 1.35px solid color(teal, default);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-medium {
            @include square(3.6rem);
        }

        &-large {
            @include square(4.2rem);
        }
    }
}
</style>
