<template>
    <component :is="as" :class="[variant, transform, CSSClasses]" class="typography">
        <slot />
    </component>
</template>

<script setup>
const props = defineProps({
    as: {
        type: [String, Object],
        default: 'span',
    },
    variant: {
        type: String,
        default: '',
    },
    transform: {
        type: String,
        default: '',
        validator: (value) => {
            return ['', 'uppercase', 'capitalize', 'capitalize-first-letter'].includes(value);
        },
    },
});
const direction = inject('direction');
const CSSClasses = computed(() => {
    return [`direction-${direction.value}`, props.hasHeaderOffset && 'header-offset'];
});
</script>

<style lang="scss" scoped>
span,
a {
    display: inline;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.capitalize-first-letter:first-letter {
    text-transform: uppercase;
}

.body {
    @include type-body;
}

.body-large {
    @include type-body-large;
}

.body-x-large {
    @include type-body-x-large;
}

.body-micro {
    @include type-body-micro;
}

.body-italic {
    @include type-body-italic;
}

.body-micro-italic {
    @include type-body-micro-italic;
}

.body-display-large {
    @include type-body-display-large;
}

.body-display-large-italic {
    @include type-body-display-large-italic;
}

.body-display-small {
    @include type-body-display-small;
}

.body-display-small-italic {
    @include type-body-display-small-italic;
}

.body-display-micro {
    @include type-body-display-micro;
}

.body-display-micro-italic {
    @include type-body-display-micro-italic;
}

.h1 {
    @include type-h1;
}

.h2 {
    @include type-h2;
}

.h3 {
    @include type-h3;
}

.h3-italic {
    @include type-h3-italic;
}

.h4 {
    @include type-h4;
}

.h5 {
    @include type-h5;
}

.h1-display {
    @include type-h1-display;
}

.h2-display {
    @include type-h2-display;
}

.h3-display {
    @include type-h3-display;
}

.h4-display {
    @include type-h4-display;
}

.h4-display-italic {
    @include type-h4-display-italic;
}

.h5-display {
    @include type-h5-display;
}

.label {
    @include type-label;
}

.label-sentence {
    @include type-label-sentence;
}

.label-small {
    @include type-label-small;
}

.label-small-sentence {
    @include type-label-small-sentence;
}

.label-micro {
    @include type-label-micro;
}

.underline {
    @include type-underline;
}

.direction-rtl {
    direction: rtl;
    &.label-small,
    &.label {
        letter-spacing: normal;
    }
}
</style>
