<template>
    <component :is="component" :url="url" :target="target" :class="CSSClasses" class="button" :aria-label="ariaLabel">
        <Typography v-if="$slots.default" variant="label" class="label">
            <slot />
        </Typography>

        <span v-if="$slots.icon || icon" class="icon">
            <slot name="icon">
                <Icon v-if="icon" :name="icon" />
            </slot>
        </span>

        <slot name="accessory" />
    </component>
</template>

<script setup>
const props = defineProps({
    variant: {
        type: String,
        default: 'primary',
        validator: (value) => {
            return ['primary', 'secondary', 'tertiary'].includes(value);
        },
    },
    theme: {
        ...themes,
    },
    size: {
        type: String,
        default: 'regular',
        validator: (value) => {
            return ['regular', 'large'].includes(value);
        },
    },
    iconPosition: {
        type: String,
        default: 'right',
        validator: (value) => {
            return ['left', 'right'].includes(value);
        },
    },
    icon: {
        type: String,
        default: null,
    },
    url: {
        type: String,
        default: null,
    },
    newTab: {
        type: Boolean,
        default: false,
    },
    ariaLabel: {
        type: String,
        default: null,
    },
    colorOverride: {
        type: String,
        default: null,
    },
    deactivated: {
        type: Boolean,
        default: false,
    },
});

const component = computed(() => (props.url ? resolveComponent('Anchor') : 'button'));
const target = computed(() => (props.newTab ? '_blank' : null));
const CSSClasses = computed(() => {
    return [
        `button-${props.variant}`,
        `theme-${props.theme}`,
        `size-${props.size}`,
        `icon-position-${props.iconPosition}`,
        props.colorOverride && 'has-colorOverride',
        props.deactivated && 'is-deactivated',
    ];
});
</script>

<style lang="scss" scoped>
@mixin padding {
    padding: 1.6rem 2.6rem 1.8rem;
}

.button {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    @include transition(background-color color, controls);
    .icon {
        @include transition(fill, controls);
    }

    .label {
        @include transition(text-decoration-color, controls);
        @include media-query(mobile) {
            width: 100%;
        }
    }

    &.is-deactivated {
        opacity: 0.4;
        &:hover {
            opacity: 1;
        }
    }
}

.button-primary,
.button-secondary {
    @include padding;

    &.size-large {
        padding: 2.3rem;
    }
}

.button-primary {
    gap: 0.8rem;
    color: color(blanc, default, 0.8);
    background-color: color(teal, default);
    border-radius: 5px;
    text-decoration: none;

    .icon {
        color: color(blanc, default, 0.8);
    }

    &:hover {
        color: color(blanc);
        background-color: color(teal, hover);
        .icon {
            fill: color(blanc);
        }
    }
    &:active {
        background-color: color(teal, active);
    }
}

.button-secondary {
    gap: 0.8rem;
    border: 1px solid color(teal, default);
    border-radius: 5px;
    color: color(blue, default, 0.7);
    text-decoration: none;
    .icon {
        fill: color(blue, default, 0.7);
    }

    &:hover {
        border-color: color(teal, hover);
        color: color(teal, hover);

        .icon {
            fill: color(teal, hover);
        }
    }
    &:active {
        border-color: color(teal, active);
        color: color(blue, dark);

        .icon {
            fill: color(blue, dark);
        }
    }

    &.theme-dark {
        color: color(blanc, default, 0.7);

        .icon {
            fill: color(blanc, default, 0.7);
        }

        &:hover {
            border-color: color(teal, hover);
            color: color(blanc);

            .icon {
                fill: color(blanc);
            }
        }
        &:active {
            border-color: color(teal, active);
        }
    }
    &.youtube {
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
    }
}

.button-tertiary {
    gap: 0.6rem;
    color: color(blue, default, 0.7);
    .icon {
        fill: color(blue, default, 0.7);
    }

    &:hover {
        color: color(teal, hover);

        .icon {
            fill: color(teal, hover);
        }

        .label {
            text-decoration-color: color(teal, hover);
        }
    }
    &:active {
        color: color(blue, dark);

        .icon {
            fill: color(blue, dark);
        }

        .label {
            text-decoration-color: color(teal, active);
        }
    }

    &.theme-dark {
        color: color(blanc, default, 0.75);
        .icon {
            fill: color(blanc, default, 0.75);
        }

        &:hover {
            color: color(blanc, default);

            .icon {
                fill: color(blanc, default);
            }
        }
    }

    .label {
        @include type-underline;
        text-decoration-color: palette(anchor-underline);
    }
}

.icon {
    flex: 1 0 auto;
}

.icon-position-left {
    flex-direction: row-reverse;
}

.label {
    @include type-label;
    text-transform: uppercase;
    flex: 0 0 auto;

    .button-tertiary & {
        @include type-underline;
        text-decoration-color: palette(anchor-underline);
    }
}

.has-colorOverride {
    &.button-primary {
        background-color: v-bind('props.colorOverride');
    }
}
</style>
