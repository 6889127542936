import { defineStore } from 'pinia';

const useCentersConfigStore = defineStore('centersConfigStore', () => {
    const allCentersConfig = ref({});
    const centerId = ref('global');

    const currentCenterConfig = computed(() => {
        return allCentersConfig.value[centerId.value]?.centerConfig?.config;
    });

    const currentCenterTopics = computed(() => {
        return allCentersConfig.value[centerId.value]?.centerConfig?.topics;
    });

    const currentCenterRegions = computed(() => {
        return allCentersConfig.value[centerId.value]?.centerConfig?.regions;
    });

    const updateCurrentCenterConfig = (newCenterId, newCenterConfig) => {
        if (newCenterId in allCentersConfig.value === false && newCenterConfig) {
            allCentersConfig.value[newCenterId] = newCenterConfig;
        }
        centerId.value = newCenterId;
    };

    return {
        allCentersConfig: allCentersConfig,
        currentCenterConfig: currentCenterConfig,
        currentCenterTopics: currentCenterTopics,
        currentCenterRegions: currentCenterRegions,
        updateCurrentCenterConfig,
    };
});

export { useCentersConfigStore };
