<template>
    <img :src="url" :alt="altText" :width="width" :height="height" srcset="" sizes="" :class="CSSClasses" />
</template>

<script setup>
const props = defineProps({
    width: {
        type: [Number, String],
        default: '',
    },
    height: {
        type: [Number, String],
        default: '',
    },
    url: {
        type: String,
        default: '',
    },
    altText: {
        type: String,
        default: '',
    },
    sizes: {
        type: Object,
    },
    variant: {
        type: String,
        default: '',
    },
    colorVariant: {
        type: String,
        default: '',
        validator: (value) => {
            return ['', 'desaturate'].includes(value);
        },
    },
});

const imageColorVariant = inject('imageColorVariant', '');
const imageSource = inject('imageSource', null);
const tierSize = inject('tierSize', 'w100');

const CSSClasses = computed(() => {
    return [
        'image',
        props.variant,
        props.colorVariant,
        imageSource === 'stream' && tierSize !== 'full_bleed' ? imageColorVariant : '',
    ];
});
</script>

<style lang="scss" scoped>
.image {
    object-fit: cover;
    max-height: 100%;

    &.desaturate {
        filter: grayscale(1);
    }
}
</style>
