import centerConfigQuery from '@gql/queries/centerConfigQuery';

export default async function () {
    const { localeFromRoute } = useLocale();

    const { currentCenter } = useCenters();

    const query = centerConfigQuery();

    const variables = {
        center: currentCenter.value.id,
        locale: localeFromRoute || 'en',
    };

    const result = await useAsyncQuery(query, variables);

    return result;
}
