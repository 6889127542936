<template>
    <figure :class="CSSClasses" class="image-block">
        <div class="image-wrap">
            <Image v-bind="image" class="image" />
            <Image v-bind="secondImage" v-if="secondImage && type === 'double'" class="image" />
        </div>

        <figcaption v-if="image.caption && !noCaption" class="caption">
            <Typography v-if="type === 'double'" variant="body-display-small" class="double-caption">{{
                caption
            }}</Typography>

            <div class="caption-wrap">
                <Typography v-if="type === 'double'" variant="body-display-micro-italic">{{ $t('left') }}:</Typography>
                <RichTextElements :elements="image.caption" bodyStyle="caption" class="caption-item" />
                <Typography v-if="type === 'double'" variant="body-display-micro-italic">{{ $t('right') }}:</Typography>
                <RichTextElements
                    v-if="secondImage && secondImage.caption && type === 'double'"
                    :elements="secondImage.caption"
                    bodyStyle="caption"
                    class="caption-item"
                />
            </div>
        </figcaption>
    </figure>
</template>

<script setup>
const props = defineProps({
    type: {
        type: String,
        default: 'actual',
        validator: (value) => {
            return ['single', 'double', 'actual', 'logo', 'heading'].includes(value.toLowerCase());
        },
    },
    size: {
        ...sizes,
    },
    image: {
        type: Object,
    },
    secondImage: {
        type: Object,
    },
    noCaption: {
        type: Boolean,
        default: false,
    },
    caption: {
        type: String,
        default: '',
    },
    class: {
        type: String,
        default: '',
    },
});

const CSSClasses = computed(() => [
    `type-${props.type}`.toLowerCase(),
    `size-${props.size}`,
    `theme-${inject('tierTheme', 'light')}`,
    props.class,
]);
</script>

<style lang="scss" scoped>
.image {
    width: 100%;
    height: 100%;
}

.double-caption,
.caption-wrap {
    padding-top: 0.8rem;
    padding-inline-start: 0.8rem;
}

.caption-item {
    &:not(:last-child) {
        margin-bottom: 1.4rem;
    }
}

.image-block.theme-dark {
    .caption {
        color: color(blanc);
    }
}

.size-full {
    position: relative;

    .caption {
        @include media-query(phone-mw) {
            position: absolute;
            bottom: 1.6rem;
            inset-inline-end: 6rem;
            color: white;
            text-shadow: 1px 1px 0 black;
            text-align: right;
        }
    }

    .image {
        max-height: var(--image-max-height);
    }
}

.type-heading {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    @include z-index(below-arbitrary);
}

.type-logo {
    background-color: color(blanc);
    border-radius: 0.4rem;
    outline: 1px solid palette(border-light);
    padding: 7%;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
        height: inherit;
        object-fit: contain;
        max-width: 100%;
    }
}

.type-single {
    .image-wrap,
    figcaption {
        max-width: 76rem;
        margin: 0 auto;
    }

    .image {
        aspect-ratio: 1/1;
    }
}

.type-double {
    .image-wrap {
        display: flex;
    }

    .image {
        width: 50%;
        aspect-ratio: 1/1;
    }

    &.size-100 {
        .image {
            aspect-ratio: 548/464;
        }

        .image:first-child {
            margin-bottom: 11.8%;
        }

        .image:last-child {
            margin-top: 11.8%;
        }
    }

    &.size-50 {
        .image:first-child {
            margin-bottom: 26%;
        }

        .image:last-child {
            margin-top: 26%;
        }
    }

    &.size-33 {
        .image:first-child {
            margin-bottom: 21%;
        }

        .image:last-child {
            margin-top: 21%;
        }
    }

    &.size-25 {
        .image:first-child {
            margin-bottom: 24%;
        }

        .image:last-child {
            margin-top: 24%;
        }
    }
}

.mobile-hero {
    display: none;
    @include media-query(phone) {
        display: block;
    }
}

.desktop-hero {
    display: block;
    @include media-query(phone) {
        .mobile-hero ~ & {
            display: none;
        }
    }
}
</style>
