export const titleToSlug = (str: string): string => {
    if (!str) {
        return '';
    }

    let output = str.toLowerCase();
    output = output.replace(/[^\w ]+/g, '');
    output = output.replace(/ +/g, '-');
    return output;
};

interface Person {
    firstName: string;
    middleName?: string;
    lastName: string;
    suffix?: string;
}

export const getPersonFullName = (person: Person): string => {
    return `${person.firstName} ${person.middleName ? `${person.middleName} ` : ''}${person.lastName}${
        person.suffix ? ` ${person.suffix}` : ''
    }`;
};

interface Locale {
    code: string;
    name: string;
}

export const getLocaleName = (locales: { value: Locale[] }, localeCode: string): string | undefined => {
    return locales.value.find((localeObj) => localeObj.code === localeCode)?.name;
};

export const toKebabCase = (str: string): string =>
    str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();

export const toCamelCase = (str: string): string => {
    let ans = str.toLowerCase();
    return ans.split(' ').reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)), '');
};
