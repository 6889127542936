import { defineStore } from 'pinia';

export const usePageDataStore = defineStore('pageDataStore', () => {
    const pageStatus = ref('');
    const pageLanguage = ref('');

    function updatePageStatus(statusString) {
        pageStatus.value = statusString;
    }

    function updatePageLanguage(localeCode) {
        pageLanguage.value = localeCode;
    }

    return {
        pageStatus,
        pageLanguage,
        updatePageStatus,
        updatePageLanguage,
    };
});
