import { defineStore } from 'pinia';

const useSessionSiteLocaleStore = defineStore(
    'sessionSiteLocale',
    () => {
        const sessionSiteLocale = ref(null);
        return { sessionSiteLocale };
    },
    {
        persist: true,
    },
);

export { useSessionSiteLocaleStore };
