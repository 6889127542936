import subscribeFormQuery from '~/gql/queries/subscribeFormQuery';

export default async function (id, contentType) {
    const variables = {
        id: id,
        contentType: contentType,
    };
    const query = subscribeFormQuery();

    writeLastQuery(query, variables, 'subscribeFormQuery');

    const result = await useAsyncQuery(query, variables);

    return result;
}
