import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin((nuxtApp) => {
    return;
    const router = useRouter();
    const {
        public: { sentry },
    } = useRuntimeConfig();
    if (!sentry.dsn) {
        console.warn('Sentry DSN not set, skipping Sentry initialization');
        return;
    }
    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
});
