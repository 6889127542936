<template>
    <GlobalLayout>
        <template #content>
            <div class="error">
                <div class="error-message">
                    <template v-if="error?.statusCode === 404">
                        <Typography as="h1" variant="h1-display">{{ $t('Page not found') }}</Typography>
                        <Typography as="p" variant="body-display-small">{{
                            $t("We're sorry, the page you are looking for doesn't exist or has been moved.")
                        }}</Typography>
                    </template>
                    <template v-else>
                        <Typography as="h1" variant="h1-display">{{ $t('Error') }}</Typography>
                        <p>
                            <strong v-if="error">{{ error?.message }}</strong>
                        </p>
                    </template>
                    <Button url="/" class="cta">{{ $t('Go home') }}</Button>
                </div>
                <img src="~/assets/svg/topographic.svg?url" class="topographic-background" />
            </div>
        </template>
    </GlobalLayout>
</template>

<script setup>
const error = useError();

const headerAttributesStore = useHeaderAttributesStore();
onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'gray' });
});
</script>

<style lang="scss" scoped>
.error {
    position: relative;
    background-color: color(grey);
    min-height: 80vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.error-message {
    @include content-section;
    padding-top: 10rem;
    padding-bottom: 10rem;

    p {
        max-width: 40rem;
    }
}

.topographic-background {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    mix-blend-mode: multiply;
}

.cta {
    margin-top: 2.6rem;
}
</style>
