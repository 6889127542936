const centerConfigQuery = () => {
    const query = gql`
        query centerConfig($center: String!, $locale: LocaleInputType) {
            centerConfig(center: $center, locale: $locale) {
                config {
                    featuredTopics {
                        id
                        path
                        title
                    }
                    featuredRegions {
                        id
                        path
                        title
                    }
                    headlineTopicRegionTags {
                        value {
                            ... on Topic {
                                id
                                path
                                title
                            }
                            ... on Region {
                                id
                                path
                                title
                            }
                        }
                    }
                }
                topics {
                    ... on Topics {
                        docs {
                            id
                            title
                            path
                        }
                    }
                }
                regions {
                    ... on Regions {
                        docs {
                            id
                            title
                            type
                            path
                            regions {
                                id
                                title
                                path
                                type
                            }
                        }
                    }
                }
                contentLocales {
                    ... on contentLocales {
                        code
                        label
                        rtl
                    }
                }
            }
        }
    `;

    return query;
};

export default centerConfigQuery;
