export default {
    pageContext: {
        type: String,
        default: '',
    },
    colorContext: {
        type: String,
        default: 'white',
    },
    colorOverride: {
        type: String,
        default: '',
    },
};
