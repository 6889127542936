<template>
    <component :is="component" :class="CSSClasses" :url="url" class="chip" :aria-label="ariaLabel">
        <Typography v-if="$slots.default" variant="label-sentence" class="text">
            <slot />
        </Typography>
    </component>
</template>

<script setup>
const props = defineProps({
    as: {
        type: [String, Object],
        default: 'button',
    },
    url: {
        type: String,
        default: '',
    },
    theme: {
        type: String,
        default: 'light',
        validator: (value) => {
            return ['light', 'dark', 'outline', 'transparent', 'teal'].includes(value);
        },
    },
    variant: {
        type: String,
        default: 'default',
        validator: (value) => {
            return ['default', 'condensed'].includes(value);
        },
    },
    type: {
        type: String,
        default: 'assist',
        validator: (value) => {
            return ['assist', 'filter'].includes(value);
        },
    },
    ariaLabel: {
        type: String,
        default: null,
    },
    class: {
        type: String,
        default: null,
    },
});

const CSSClasses = computed(() => {
    return [`theme-${props.theme}`, `variant-${props.variant}`, `type-${props.type}`, props.class];
});

const component = computed(() => {
    return props.url ? resolveComponent('Anchor') : props.as;
});
</script>

<style lang="scss" scoped>
.theme-dark.chip {
    outline: 1px solid color(noir, default, 0.1);

    background-color: color(noir, default, 0.1);

    &:hover {
        background-color: color(noir, default, 0.05);
        outline: 1px solid color(blanc);
    }
    &:active {
        background-color: color(noir, default, 0.3);
    }
}

.theme-teal.chip {
    outline: 1px solid color(teal, default);

    background-color: color(teal, default);

    .text {
        color: white;
    }

    &:hover {
        background-color: color(teal, default, 0.5);
        outline: 1px solid color(blanc);
    }
    &:active {
        background-color: color(teal, default, 0.3);
    }
}

// .theme-transparent.chip {
//     background-color: color(noir, default, 0.1);
//     outline: 1px solid color(blanc, default, 0.3);
//     .text {
//         color: color(blanc, default, 0.8);
//     }

//     &:hover {
//         background-color: color(noir, default, 0.05);
//         outline: 1px solid color(blanc);
//         .text {
//             color: color(blanc);
//         }
//     }
//     &:active {
//         background-color: color(noir, default, 0.3);
//         outline: 1px solid color(blanc);
//         .text {
//             color: color(blanc);
//         }
//     }
// }

.theme-outline.chip {
    outline: 1px solid color(blue, default, 0.2);
    background-color: color(blanc);
    .text {
        color: color(blue);
    }

    &:hover {
        background-color: color(grey, default, 0.1);
    }
    &:active {
        background-color: color(grey, default, 0.7);
    }
}

.variant-condensed.chip {
    padding: 0.9rem 1.6rem;
}

.chip {
    background-color: color(tag-blue);
    padding: 0.9rem 1.6rem 1.2rem;
    border-radius: 100rem;
    @include transition(background-color outline, controls);
    &:hover {
        background-color: color(tag-blue, hover);
    }
    &:active {
        background-color: color(tag-blue, active);
    }
}

.text {
    white-space: nowrap;
    color: color(blue, dark);
    letter-spacing: -0.02em;
}
.type-assist {
    .text {
        opacity: 0.8;
        @include transition(opacity, controls);
    }
    &:hover {
        .text {
            opacity: 1;
        }
    }
}
.lang-ru .nav-two .variant-condensed.chip {
    padding: 0.8rem 0.7rem;
}
</style>
