export default defineNuxtPlugin((nuxtApp) => {
    const shouldLogError = (error) => {
        // Skip logging for 404 Page Not Found errors
        if (error.statusCode === 404 && error.statusMessage === 'Page Not Found') {
            return false;
        }
        return true;
    };

    nuxtApp.vueApp.config.errorHandler = (error, context, info) => {
        //if (shouldLogError(error)) {
        console.error('Error handler invoked', {
            error: error,
            route: nuxtApp.$router.currentRoute.value.fullPath,
        });
        //}
    };

    nuxtApp.hook('vue:error', (error, context, info) => {
        if (shouldLogError(error)) {
            console.error('Error handler invoked in vue:error hook', {
                error: error,
                route: nuxtApp.$router.currentRoute.value.fullPath,
            });
        }
    });

    nuxtApp.hook('apollo:error', (error, context, info) => {
        if (shouldLogError(error)) {
            const errorDetails = {
                error: error,
                route: nuxtApp.$router.currentRoute.value.fullPath,
            };

            // Log the GraphQL operation details
            if (error.operation) {
                errorDetails.operation = {
                    operationName: error.operation.operationName,
                    variables: error.operation.variables,
                };
            }

            // Log network error details, including headers
            if (error.networkError) {
                errorDetails.networkError = error.networkError;

                // Log response details if available
                if (error.networkError.result) {
                    errorDetails.response = {
                        status: error.networkError.statusCode,
                        headers: error.networkError.response?.headers,
                        body: error.networkError.result,
                    };
                }
            }

            // Log GraphQL errors
            if (error.graphQLErrors) {
                errorDetails.graphQLErrors = error.graphQLErrors;
            }

            // Extract and log headers and request details for network errors
            if (error.networkError && error.networkError.response) {
                errorDetails.responseDetails = {
                    status: error.networkError.response.status,
                    headers: Object.fromEntries(error.networkError.response.headers.entries()),
                    body: error.networkError.response.body,
                };

                // Log original request details if available
                if (error.networkError.response.request) {
                    errorDetails.requestDetails = {
                        url: error.networkError.response.request.url,
                        method: error.networkError.response.request.method,
                        headers: Object.fromEntries(error.networkError.response.request.headers.entries()),
                        body: error.networkError.response.request.body,
                    };
                }
            }

            console.error('Error handler invoked in apollo:error hook', errorDetails);
        }
    });
});
