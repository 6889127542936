<template>
    <div class="app">
        <SiteHeader />
        <NuxtLayout>
            <NuxtLoadingIndicator color="#00b8ad" />
            <slot name="content">
                <NuxtPage />
            </slot>
        </NuxtLayout>
        <SiteFooter />

        <div id="teleported-content"></div>

        <EmbargoModal v-if="hasEmbargoModal && showEmbargoModal" @close-embargo-modal="hideEmbargoModal" />
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

const { bodyScrollDisabled } = storeToRefs(useBodyAttributesStore());
const { currentCenter } = useCenters();
const { locale, setLocale, localeFromRoute } = useLocale();
const { sessionSiteLocale } = useSessionSiteLocale();

const centersConfigStore = useCentersConfigStore();
const { pageStatus } = storeToRefs(usePageDataStore());
const { contentDirection } = useDirection();

const nuxtApp = useNuxtApp();

nuxtApp.hook('page:finish', () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
});
const bodyClasses = computed(() => [bodyScrollDisabled.value ? 'no-scroll' : '']);

provide('direction', contentDirection);

useHead(
    computed(() => {
        return {
            htmlAttrs: {
                lang: locale.value,
                dir: contentDirection.value,
            },
            bodyAttrs: {
                class: bodyClasses,
            },
        };
    }),
);

const getValidLocale = (localeCode) => {
    return currentCenter?.value?.localeCodes?.includes(localeCode) ? localeCode : 'en';
};

const centerConfig = await useCenterConfig();
centersConfigStore.updateCurrentCenterConfig(currentCenter.value.id, centerConfig.data);
const centerLocale = getValidLocale(localeFromRoute.value || sessionSiteLocale.value || locale);
await setLocale(centerLocale);

const hasEmbargoModal = computed(() => {
    return pageStatus.value === 'embargoed';
});

const showEmbargoModal = ref(true);
const hideEmbargoModal = () => {
    showEmbargoModal.value = false;
};
</script>

<style lang="scss">
body.no-scroll {
    overflow: hidden;
    height: 100%;
}
</style>
