import { storeToRefs } from 'pinia';

export default function () {
    const store = useSessionSiteLocaleStore();
    const { sessionSiteLocale } = storeToRefs(store);

    return {
        sessionSiteLocale,
    };
}
