export default function () {
    const { locale, locales, setLocale } = useI18n();
    const { currentCenter } = useCenters();

    const currentLocaleName = computed(() => {
        return getLocaleName(locales, locale.value);
    });

    const currentLocales = computed(() => {
        return currentCenter.value.localeCodes.map((code) => {
            return {
                code,
                name: getLocaleName(locales, code),
            };
        });
    });

    const currentRoute = useRoute();

    //checks if the lang param in route exists in site locales. can return undefined if no match is found.
    const getLocaleFromRoute = (route) => {
        return locales.value.find((locale) => locale.code === route.query?.lang)?.code;
    };

    const localeFromRoute = computed(() => {
        return getLocaleFromRoute(currentRoute);
    });

    return { locale, currentLocaleName, currentLocales, setLocale, localeFromRoute };
}
