export default function () {
    const { locale } = useLocale();
    const { localeFromRoute } = useLocale();

    // POST LAUNCH FIXME: this only works for arabic, we can expand this to support
    // any supported content language (the BE is passing available locales/directions in the centerconfigquery)
    const contentDirection = computed(() => {
        return localeFromRoute.value === 'ar' || locale.value === 'ar' ? 'rtl' : 'ltr';
    });
    const siteDirection = ref(locale.value === 'ar' ? 'rtl' : 'ltr');

    watch(locale, (newLocale) => {
        if (newLocale === 'ar') {
            siteDirection.value = 'rtl';
        } else {
            siteDirection.value = 'ltr';
        }
    });

    return {
        siteDirection: siteDirection,
        contentDirection: contentDirection,
    };
}
